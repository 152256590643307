document.addEventListener('DOMContentLoaded', function (event) {
    // Your code to run since DOM is loaded and ready
    const menuButton = document.getElementById('menu-button');
    const menuItems  = document.getElementById('menu-items');

    if (menuButton && menuItems) {
        menuButton.addEventListener('click', function () {
            const isExpanded = menuButton.getAttribute('aria-expanded') === 'true';
            menuButton.setAttribute('aria-expanded', !isExpanded);
            menuItems.classList.toggle('hidden');
        });

        // Close the dropdown menu if the user clicks outside of it
        document.addEventListener('click', function (event) {
            if (!menuButton.contains(event.target) && !menuItems.contains(event.target)) {
                menuButton.setAttribute('aria-expanded', 'false');
                menuItems.classList.add('hidden');
            }
        });
    } else {
        console.error('Menu button or menu items not found.');
    }

    /**
     * Toggles the visibility of the fullscreen menu and updates the menu icon.
     *
     * @return {void}
     */
    const burgerMenuButtons = document.getElementsByClassName('burgerMenuBtn');
    for (let i = 0; i < burgerMenuButtons.length; i++) {
        burgerMenuButtons[i].addEventListener('click', function () {
            document.getElementById('fullscreen-menu').classList.toggle('hidden');
            document.getElementById('burgerMenuSvg').classList.toggle('hidden');
            document.getElementById('burgerMenuCloseSvg').classList.toggle('hidden');
        });
    }

    function toggleDropdown()
    {
        document.getElementById('menu-items').classList.toggle('hidden');
    }

    function toggleAnswer(id, btn)
    {
        var answer = document.getElementById(id);
        var icon   = btn.querySelector('i');
        var parent = btn.closest('.accordion-item');

        if (answer.classList.contains('hidden')) {
            answer.classList.remove('hidden');
            icon.classList.remove('fa-chevron-down');
            icon.classList.add('fa-chevron-up');
            parent.classList.add('bg-lightBeigy');
        } else {
            answer.classList.add('hidden');
            icon.classList.remove('fa-chevron-up');
            icon.classList.add('fa-chevron-down');
            parent.classList.remove('bg-lightBeigy');
        }
    }

    // Add shadow to header on scroll
    document.addEventListener('scroll', function () {
        const header = document.getElementById('main-header');
        if (header) {
            header.classList.toggle('bg-white', window.scrollY > 0);
            header.classList.toggle('shadow', window.scrollY > 0);
        }
    });

    let searchBtn         = document.getElementById('search-btn'),
        searchBox         = document.getElementById('search-box'),
        btnCloseSearchBox = document.getElementById('btn-close-search-box');

    if (searchBtn && searchBox) {
        searchBtn.addEventListener('click', () => {
            searchBox.classList.toggle('hidden');
            searchBtn.classList.toggle('active-search');
        });
    }

    if (btnCloseSearchBox && searchBox) {
        btnCloseSearchBox.addEventListener('click', () => {
            searchBox.classList.toggle('hidden');
            searchBtn.classList.toggle('active-search');
        });
    }

    if (searchBtn && searchBox) {
        window.addEventListener('click', function (e) {
            // console.log(searchBtn.contains(e.target));
            if (!searchBtn.contains(e.target) && !searchBox.contains(e.target)) {
                searchBox.classList.add('hidden');
            }
        });
    }

    let mobileSearch = document.getElementById('search');
    let Msearch      = document.getElementById('Msearch');
    if (mobileSearch) {
        mobileSearch.addEventListener('click', function () {
            Msearch.classList.toggle('hidden');
        });
    }
});
